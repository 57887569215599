<template>
  <div>
    <a-tree
      :tree-data="data"
      :showIcon="true"
      @select="onSelect"
      :replaceFields="{ children: 'childrenDepartmentList', key: 'departmentId' }"
      @expand="onExpand"
      :expandedKeys="expandedKeys"
      :auto-expand-parent="autoExpandParent"
      :disabled="isDisabled"
    >
      <!--左侧展开下级图标  -->
      <template #switcherIcon>
        <CaretDownOutlined style="font-size:18px" />
      </template>
      <template #custom="item">
        <div class="tree-item " :style="select === item.departmentId ? 'background: #3399FF;' : ''">
          <img
            v-if="[0, 3].includes(item.type)"
            :src="
              select === item.departmentId
                ? require('@/assets/images/staff/company-red.png')
                : require('@/assets/images/staff/company.png')
            "
            class="org-icon"
            alt=""
            srcset=""
          />
          <img
            v-else
            :src="
              select === item.departmentId
                ? require('@/assets/images/staff/department-red.png')
                : require('@/assets/images/staff/department.png')
            "
            class="org-icon"
            alt=""
            srcset=""
          />
          <a-tooltip placement="topLeft" :title="item.departmentName" v-if="item.departmentName.length > 11">
            <div
              class="tree-item-sty department-item"
              :class="select === item.departmentId ? 'select' : ''"
              v-html="handledHtml(item)"
            />
          </a-tooltip>
          <div
            v-else
            class="tree-item-sty department-item"
            :class="select === item.departmentId ? 'select' : ''"
            v-html="handledHtml(item)"
          />
          <!-- <a-popover
            v-if="
              item.departmentId &&
                getRoles('PC_DEPT_UPDATE,PC_DEPT_DELETE') &&
                item.enterpriseId == enterpriseId &&
                !item.disabled
            "
            overlayClassName="global-tree-popover"
            placement="bottom"
          >
            <template #content>
              <div
                class="tree-btn-item tree-btn-edit"
                v-if="typeof item.canCreateStaff == 'boolean' ? item.canCreateStaff : item.enterpriseId == enterpriseId"
                v-btn="'PC_DEPT_ADD'"
                @click="handledEdit('addChild', item)"
              >
                添加子组织
              </div>
              <div class="tree-btn-item tree-btn-edit" v-btn="'PC_DEPT_UPDATE'" @click="handledEdit('edit', item)">
                修改组织
              </div>
              <div class="tree-btn-item tree-btn-delete" v-btn="'PC_DEPT_DELETE'" @click="handledEdit('delete', item)">
                删除组织
              </div>
            </template>
            <div class="tree-btn">
              <MoreOutlined style="font-size: 18px" v-if="isShowOperation" />
            </div>
          </a-popover> -->
        </div>
      </template>
    </a-tree>
  </div>
</template>

<script>
import { CaretDownOutlined, MoreOutlined } from '@ant-design/icons-vue'
import { reactive, toRefs, watch, onMounted, computed } from 'vue'
import { useRoute, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isDisabled: {
      type: Boolean
    },
    isShowOperation: {
      type: Boolean,
      default: true
    },
    searchValue: undefined,
    currentDepartId: undefined
  },
  components: {
    CaretDownOutlined,
    MoreOutlined
  },
  emits: ['edit', 'onSelect', 'search'],
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      enterpriseId: JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.enterpriseId,
      select: '',
      autoExpandParent: true,
      expandedKeys: []
    })

    // 添加、编辑人员时，保存组织树状态
    onBeforeRouteLeave((to, from) => {
      const staffRoutes = ['/businessManage/staff/add', '/businessManage/staff/edit']
      if (staffRoutes.includes(to.path)) {
        let lastDepartmentInfo = { ...store.getters.lastDepartmentInfo }
        lastDepartmentInfo.expandedKeys = state.expandedKeys
        lastDepartmentInfo.select = state.select
        store.commit('globalData/SET_LAST_DEPARTMENT_INFO', lastDepartmentInfo)
      }
    })

    //处理树结构数据
    onMounted(() => {
      console.log('查看树结构的数据', props.data)
      state.expandedKeys = [0]
      state.autoExpandParent = false
      props.currentDepartId && (state.select = '' + props.currentDepartId)

      // 读取缓存的树结构
      if (store.getters.lastDepartmentInfo.departmentId >= 0) {
        let lastDepartmentInfo = { ...store.getters.lastDepartmentInfo }
        state.expandedKeys = lastDepartmentInfo.expandedKeys
        state.select = lastDepartmentInfo.select
        store.commit('globalData/ADD_LAST_DEPARTMENT_INFO')
      }
    })

    const dataList = computed(() => {
      const generate = tempArr => {
        return tempArr.reduce((result, item) => {
          const chilren = generate(item.childrenDepartmentList || [])
          return [...result, ...chilren, item]
        }, [])
      }
      return generate(props.data)
    })

    //操作树结构
    const onSelect = (selectedKeys, e) => {
      // return
      console.log(e)
      state.select = e.node.eventKey
      emit('onSelect', e.node.dataRef)
    }
    const handledEdit = (type, item) => {
      emit('edit', type, item)
    }
    const onExpand = keys => {
      state.expandedKeys = keys
      state.autoExpandParent = false
    }
    const handledHtml = item => {
      return item.departmentName.replace(
        props.searchValue,
        `<div style='color: #b32d27;display: inline'>${props.searchValue}</div>`
      )
    }

    // 拥有其中一个权限就显示
    const getRoles = value => {
      const arr = value.split(',')

      return arr.filter(it => {
        return !!route.meta.permission.find(item => {
          // if (item.includes(it.toUpperCase())) {
          // }
          return item.includes(it.toUpperCase())
        })
      }).length
    }

    // 搜索
    watch(
      () => props.searchValue,
      value => {
        if (!value) {
          state.expandedKeys = [0]
          state.autoExpandParent = true
          return
        }
        const searchIds = dataList.value
          .filter(item => {
            return item.departmentName.includes(value)
          })
          .map(item => item.departmentId)
        console.log({ searchIds, dataList })
        state.autoExpandParent = true
        state.expandedKeys = searchIds
      }
    )

    // 刷新数据
    watch(
      () => props.data,
      newValue => {
        console.log('===', newValue)
        if (newValue && !props.currentDepartId) {
          state.select = ''
        }
      },
      { immediate: true, deep: true }
    )
    // watch(
    //   () => [props.searchValue, props.data],
    //   ([value, newValue]) => {
    //     if (newValue) {
    //       state.select = ''
    //     }
    //     if (!value) {
    //       state.expandedKeys = [0]
    //       state.autoExpandParent = true
    //       return
    //     }
    //     const tempVal = dataList.value.find(item => {
    //       return item.departmentName.includes(value)
    //     })
    //     console.log({ tempVal, dataList })
    //     state.autoExpandParent = true
    //     state.expandedKeys = tempVal?.departmentId ? [tempVal.departmentId] : []
    //     // emit('search', value)
    //   },
    //   { immediate: true }
    // )

    return {
      ...toRefs(state),
      onSelect,
      handledEdit,
      onExpand,
      handledHtml,
      getRoles
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.ant-tree-node-content-wrapper) {
  padding: 0 !important;
}
.tree-item-style {
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .tree-item-sty {
    display: inline;
  }
}

.department-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 160px;
}

.tree-item {
  &:hover{
    background: #b6d9fc !important;
  }
  display: flex;
  align-items: center;
  padding: 0 25px 0 5px;
  .org-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  .tree-btn {
    position: absolute;
    right: 0;
    background: #ffffff;
  }

  .select {
    color: #fff;
  }
}
</style>
